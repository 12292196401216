import Script from 'next/script';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { appSettingsState } from '@/app/lib/redux';

export const GrooveWidget = () => {
  const { mobileFiltersOpen, detailModalVisible } = useSelector(appSettingsState);
  const shouldShowButton = !(mobileFiltersOpen || detailModalVisible);

  if (!shouldShowButton) return null;

  const grooveId = '54f2ded3-6f1a-4558-ac2d-22f6f16286fe';
  return (
    <Script id={'groove-sdk'} strategy="lazyOnload">
      {`
      !function(e,t){if(!e.groove){var i=function(e,t){return Array.prototype.slice.call(e,t)},a={widget:null,loadedWidgets:{},classes:{Shim:null,Embeddable:function(){this._beforeLoadCallQueue=[],this.shim=null,this.finalized=!1;var e=function(e){var t=i(arguments,1);if(this.finalized){if(!this[e])throw new TypeError(e+"() is not a valid widget method");this[e].apply(this,t)}else this._beforeLoadCallQueue.push([e,t])};this.initializeShim=function(){a.classes.Shim&&(this.shim=new a.classes.Shim(this))},this.exec=e,this.init=function(){e.apply(this,["init"].concat(i(arguments,0))),this.initializeShim()},this.onShimScriptLoad=this.initializeShim.bind(this),this.onload=void 0}},scriptLoader:{callbacks:{},states:{},load:function(e,i){if("pending"!==this.states[e]){this.states[e]="pending";var a=t.createElement("script");a.id=e,a.type="text/javascript",a.async=!0,a.src=i;var s=this;a.addEventListener("load",(function(){s.states[e]="completed",(s.callbacks[e]||[]).forEach((function(e){e()}))}),!1);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n)}},addListener:function(e,t){"completed"!==this.states[e]?(this.callbacks[e]||(this.callbacks[e]=[]),this.callbacks[e].push(t)):t()}},createEmbeddable:function(){var t=new a.classes.Embeddable;return e.Proxy?new Proxy(t,{get:function(e,t){return e instanceof a.classes.Embeddable?Object.prototype.hasOwnProperty.call(e,t)||"onload"===t?e[t]:function(){e.exec.apply(e,[t].concat(i(arguments,0)))}:e[t]}}):t},createWidget:function(){var e=a.createEmbeddable();return a.scriptLoader.load("groove-script","https://${grooveId}.widget.cluster.groovehq.com/api/loader"),a.scriptLoader.addListener("groove-iframe-shim-loader",e.onShimScriptLoad),e}};e.groove=a}}(window,document);
      window.groove.widget = window.groove.createWidget();
      window.groove.widget.init('${grooveId}', {preferLocal: true})`}
    </Script>
  );
};

export const FacebookPixelSDK = () => (
  <>
    <Script id="fb-pixel" strategy="afterInteractive">
      {`
         !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${process.env.NEXT_PUBLIC_FB_PIXEL_ID as string});
          fbq.disablePushState = true; // disable automatic fire PageView on client side navigation without custom EventID
          fbq.allowDuplicatePageViews = true; // enables to send PageView event on client side navigation with custom EventID
        `}
    </Script>
    <noscript>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt=""
        height="1"
        width="1"
        style={{ display: 'none' }}
        src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FB_PIXEL_ID as string}&ev=PageView&noscript=1`}
      />
    </noscript>
  </>
);

export const RTBHouseSDK = () => (
  <Script id="rtb-pixel" strategy="afterInteractive">
    {`
      (function (w,d,dn,t){w[dn]=w[dn]||[];w[dn].push({eventType:'init',value:t,dc:''});
      var f=d.getElementsByTagName('script')[0],c=d.createElement('script');c.async=true;
      c.src='https://tags.creativecdn.com/${process.env.NEXT_PUBLIC_RTB_PIXEL_ID as string}.js';
      f.parentNode.insertBefore(c,f);})(window,document,'rtbhEvents','${
        process.env.NEXT_PUBLIC_RTB_PIXEL_ID as string
      }');
     `}
  </Script>
);

export const BeamerSnippet = () => (
  <>
    <Script id="beamer-snippet" strategy="afterInteractive">
      {`
          var beamer_config = {
            product_id : 'NlNVGdAH24583', //DO NOT CHANGE: This is your product code on Beamer
            };
          var beamerTrigger = document.getElementById('beamerTrigger');
          if (beamerTrigger) {
            beamerTrigger.style.display = 'flex';
          }
      `}
    </Script>
    <Script type="text/javascript" src="https://app.getbeamer.com/js/beamer-embed.js" async={true} />
  </>
);

import { usePathname, useRouter } from 'next/navigation';

import React from 'react';
import { useTranslation } from 'next-i18next';

function useSearchSubmitHandler(searchTerm: string) {
  const router = useRouter();
  const pathname = usePathname();
  const { i18n } = useTranslation();

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    try {
      const response = await fetch('/api/int/search/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          searchTerm,
          locale: i18n.language,
          rootPath: pathname.indexOf('home-living') > -1 ? 'home-living' : '',
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      router.push(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('There was an error submitting the form:', error);
    }
  };

  return handleSubmit;
}

export default useSearchSubmitHandler;

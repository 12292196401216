'use client';

import Script from 'next/script';
import React from 'react';
import useCmp from '@/app/ui/hooks/useCmp';

import { BeamerSnippet, FacebookPixelSDK, GrooveWidget, RTBHouseSDK } from './sdk';
import { AbTestsType } from '@/app/lib/abTests';

// const serverGtmId = process.env.NEXT_PUBLIC_SERVER_GTM_ID;
// const clientGtmId = process.env.NEXT_PUBLIC_CLIENT_GTM_ID;

const sstHostMapping: { [key: string]: string } = {
  'loc.mybestbrands.de': 'dev.mybestbrands.de',
  'dev.mybestbrands.de': 'dev.mybestbrands.de',
  'pre.mybestbrands.de': 'pre.mybestbrands.de',
  'www.mybestbrands.de': 'live.mybestbrands.de',

  'loc.mybestbrands.com': 'dev.mybestbrands.com',
  'dev.mybestbrands.com': 'dev.mybestbrands.com',
  'pre.mybestbrands.com': 'live.mybestbrands.com',
  'www.mybestbrands.com': 'live.mybestbrands.com',
};

const Tracking = ({
  host,
  consentsFromCookie,
  abTests,
}: {
  host: string;
  consentsFromCookie: string;
  abTests: AbTestsType;
}) => {
  const isCom = host.indexOf('.com') > -1;
  const consents = useCmp(consentsFromCookie);

  return (
    <>
      <Script id="gtag-default" strategy={'afterInteractive'}>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied',
              'analytics_storage': 'denied'
            });
        `}
      </Script>

      <Script
        src={`https://web.cmp.usercentrics.eu/ui/loader.js`}
        data-settings-id={!isCom ? 'TB1Cmi76w' : ''}
        data-ruleset-id={isCom ? 'KNKXPVlQ9' : ''}
        async={true}
        id="usercentrics-cmp"
      />

      <Script src="/lastsource.min.js" id="last-source-min" />
      <Script
        src={`https://analytics-${sstHostMapping[host] || 'dev.mybestbrands.com'}/gtag/js?id=${
          process.env.NEXT_PUBLIC_SERVER_GTM_ID
        }`}
        id="gtm-sst"
      />
      <Script src={`https://www.googletagmanager.com/gtm.js?id=${process.env.NEXT_PUBLIC_CLIENT_GTM_ID}`} id="gtm" />
      <Script id="gtag" strategy="afterInteractive">
        {`
            var uc_consent=${JSON.stringify(consents || {})};
            var ab_tests=${JSON.stringify(abTests).toLowerCase()};

            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            
            var l = document.location;
            window.dataLayer.push({
              originalLocation: l.protocol + '//' + l.hostname + l.pathname + l.search
            });
            
            var params = {
              transport_url: 'https://analytics-${sstHostMapping[host]}',
              send_page_view: false,
              ...ab_tests
            };
            
            if (document.cookie.indexOf('ga4_debug') > -1) {
              params.debug_mode = true
            };
            
            gtag('config', '${process.env.NEXT_PUBLIC_SERVER_GTM_ID}', params); // Server config
            gtag('config', '${process.env.NEXT_PUBLIC_CLIENT_GTM_ID}'); // Client config
        `}
      </Script>
      {consents?.Facebook_Pixel && <FacebookPixelSDK />}
      {isCom && consents?.Groove && <GrooveWidget />}
      {!isCom && consents?.RTB_House && <RTBHouseSDK />}
      {!isCom && consents?.Beamer && <BeamerSnippet />}
    </>
  );
};

export default Tracking;

import { toggleAuthModalVisible } from '@/app/lib/redux';
import { AuthActions } from '@mybestbrands/types';
import React from 'react';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { handleAuthApiResponse } from '@/app/utils/profile';

type Props = {
  apiUrl: string;
  email: string;
  token: string;
};

const ResetPassword = ({ apiUrl, email, token }: Props) => {
  const { t } = useTranslation('profile');
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordR, setNewPasswordR] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [success, setSuccess] = React.useState(false);

  const isFormValid = email && newPassword && newPasswordR && newPassword.length >= 6 && newPassword === newPasswordR;

  const sendReset = () => {
    if (!isFormValid) return;

    fetch(apiUrl + '/user/reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserEmail: decodeURIComponent(email),
        Token: token,
        NewPassword: newPassword,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        handleAuthApiResponse(
          t,
          data,
          () => {
            setSuccess(true);
          },
          (e: string[]) => setErrors(e)
        );
      });
  };

  if (success) {
    return (
      <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
        <div className={'d-flex flex-column gap-3'}>
          <div className={'text-center fw-bold fs-5'}>
            Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort anmelden.
          </div>
          <div className={'d-flex justify-content-center'}>
            <button
              className={'btn btn-black-hover-light w-50 p-2'}
              onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
              Zurück zum Login
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
      <div className={'d-flex flex-column gap-3'}>
        <div className={'text-center fw-bold fs-5'}>Geben Sie Ihr neues Passwort</div>
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type={'password'}
          placeholder={'Neues Passwort'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type={'password'}
          placeholder={'Neues Passwort wiederholen'}
          value={newPasswordR}
          onChange={(e) => setNewPasswordR(e.target.value)}
        />

        <div className={'d-flex justify-content-center'}>
          <button className={'btn btn-black-hover-light w-50 p-2'} onClick={sendReset} disabled={!isFormValid}>
            Passwort zurücksetzen
          </button>
        </div>
        <div className={'d-flex justify-content-center'}>
          <div
            className={'d-flex align-items-center cursor-pointer'}
            onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
            <Image width={18} height={14} src="/intstatic/icons/black-arrow-left.svg" alt="close" />
            <div>Zurück zum Login</div>
          </div>
        </div>
        {errors.map((error, index) => (
          <div key={index} className={'text-danger'}>
            {error}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResetPassword;

import { useState, useEffect } from 'react';

const useScrollToTop = (): number => {
  const getScrollTop = (): number => {
    if (typeof window !== 'undefined') {
      return document.body.scrollTop || document.documentElement.scrollTop;
    }
    return 0;
  };

  const [scrollTop, setScrollTop] = useState(getScrollTop());

  useEffect(() => {
    // https://stackoverflow.com/a/56239226
    let timeout: ReturnType<typeof setTimeout>;

    const handleSetScriptTop = (): void => {
      setScrollTop(getScrollTop());
    };

    const handleScrollTop = (): void => {
      if (timeout) clearTimeout(timeout);
      if (window.requestAnimationFrame) {
        timeout = setTimeout(() => window.requestAnimationFrame(handleSetScriptTop), 500);
      } else timeout = setTimeout(handleSetScriptTop, 1000);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScrollTop);
      window.addEventListener('move', handleScrollTop);

      return (): void => {
        window.removeEventListener('scroll', handleScrollTop);
        window.removeEventListener('move', handleScrollTop);
      };
    }
  }, []);

  return scrollTop;
};

export default useScrollToTop;

'use client';

import { useState } from 'react';
import styles from './styles.module.scss';
import Image from 'next/image';
const TrustPilotWidget = () => {
  const arr = Array.from({ length: 4 });
  const [isDesktopHovered, setDesktopHovered] = useState(false);
  const handleDesktopHover = () => setDesktopHovered(!isDesktopHovered);

  return (
    <a
      href="https://de.trustpilot.com/review/www.mybestbrands.de"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}>
      <div
        style={{ filter: isDesktopHovered ? 'invert(100%)' : 'none' }}
        className={`d-flex justify-content-center align-items-center ${styles.containerWidget}`}
        onMouseEnter={() => handleDesktopHover()}
        onMouseLeave={() => handleDesktopHover()}>
        <div className="d-block">
          <div className={styles.textWidget}>BEWERTEN SIE MYBESTBRANDS</div>
          <div className="d-flex justify-content-center mt-2">
            {arr.map((_, index) => {
              return <Image src="/intstatic/icons/star.svg" alt="star" width={'20'} height={'20'} key={index} />;
            })}
          </div>
        </div>
      </div>
    </a>
  );
};

export default TrustPilotWidget;

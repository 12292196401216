import { IProduct } from '@mybestbrands/types';
import { isWithinCountdownInterval } from '@/app/helpers/fomofeatures';

export const trackSSTEvent = (eventName: string, params: object | null) => {
  setTimeout(() => {
    if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
      if (params) window.gtag('event', eventName, params);
      else window.gtag('event', eventName);
    }
  }, 500);
};

export const mapProductsToEcommerceItems = (products: IProduct[], promotionHeadline?: string, coupon?: string) =>
  products.map((product) => {
    const { epc, productId, retailerName, name, brandName, genders, categoryTagNames } = product;

    const ret = {
      item_id: productId,
      item_name: name,
      affiliation: retailerName,
      currency: 'EUR',
      item_brand: brandName,
      item_category: (genders || []).join('/'),
      item_category2: (categoryTagNames || []).join('/'),
      price: epc / 100,
      quantity: 1,
    };

    if (promotionHeadline) {
      return {
        ...ret,
        promotion_id: promotionHeadline.toLowerCase().split(' ').join('_'),
        promotion_name: promotionHeadline,
      };
    }

    if (isWithinCountdownInterval(coupon)) {
      return {
        ...ret,
        coupon: coupon,
      };
    }

    return ret;
  });

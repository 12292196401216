import { reduceConsentsArrayToObject } from '@/app/utils/common';
import { COOKIE_NAMES } from '@/app/constants';
import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';

type CmpServices = { name: string; consent: { given: boolean } };
type Consents = {
  Google_Ads: boolean;
  Groove: boolean;
  Facebook_Pixel: boolean;
  RTB_House: boolean;
  Beamer: boolean;
  Google_Analytics: boolean;
  Dynamic_Yield: boolean;
};

const useCmp = (consentsFromServer: string) => {
  const initialConsents = reduceConsentsArrayToObject(
    JSON.parse(decodeURIComponent(consentsFromServer || '[]'))
  ) as Consents;
  const [consents, setConsents] = useState<Consents>(initialConsents);

  const handleCMP = () => {
    const isMbbDomain = window.location.hostname.indexOf('.mybestbrands.') > -1;
    const domainWithoutSubdomain = '.' + window.location.hostname.split('.').slice(-2).join('.');

    // Disable consents (tracking) if domain is not MBB.COM
    if (!isMbbDomain || typeof window.__ucCmp !== 'object') return;

    window.__ucCmp.getConsentDetails().then((cmp: { services: object; consent: { type: 'IMPLICIT' | 'EXPLICIT' } }) => {
      const services = Object.values(cmp.services) as CmpServices[];
      if (!services.length || cmp.consent.type === 'IMPLICIT') return;

      const consentsArr = services
        .filter((c) => {
          const hasConsent = c.consent.given;
          const notUC = c.name !== 'Usercentrics Consent Management Platform';
          return hasConsent && notUC;
        })
        .map((c) => c.name.split(' ').join('_'));

      if (!consentsArr.length) return;

      const consentsObj = reduceConsentsArrayToObject(consentsArr) as Consents;
      window['uc_consent'] = consentsObj;
      const d = new Date();
      d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000); // set to full year
      document.cookie = `${COOKIE_NAMES.CONSENTS}=${JSON.stringify(
        consentsArr
      )};expires="${d.toUTCString()}";domain=${domainWithoutSubdomain};path=/`;

      setConsents({ ...consentsObj });
    });
  };

  useEffect(() => {
    const isMbbDomain = window.location.hostname.indexOf('.mybestbrands.') > -1;
    // Disable consents (tracking) if domain is not MBB.COM
    if (!isMbbDomain) return;

    const fromCookie = getCookie(COOKIE_NAMES.CONSENTS);
    const consentsFromCookie = reduceConsentsArrayToObject(
      JSON.parse(decodeURIComponent((fromCookie as string) || '[]'))
    ) as Consents;

    if (!!Object.keys(consentsFromCookie).length) {
      setConsents({ ...consentsFromCookie });
      window['uc_consent'] = consentsFromCookie;
    }

    //window.addEventListener('UC_UI_VIEW_CHANGED', handleCMP);
    window.addEventListener('UC_GCM_UPDATE', handleCMP);
    return (): void => {
      //window.removeEventListener('UC_UI_VIEW_CHANGED', handleCMP);
      window.removeEventListener('UC_GCM_UPDATE', handleCMP);
    };
  }, []);

  return consents;
};

export default useCmp;

import { useDispatch } from 'react-redux';
import { toggleAuthModalVisible } from '@/app/lib/redux';
import { AuthActions } from '@mybestbrands/types';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { trackSSTEvent } from '@/app/utils/tracking';

type Props = {
  status: string;
  apiUrl: string;
};

const RegistrationActivation = ({ status }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['profile']);
  useEffect(() => {
    trackSSTEvent('sign_up', { method: 'email' });
  }, []);
  return (
    <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
      <div className={'d-flex flex-column gap-3'}>
        <div className={'text-center fw-bold fs-5'}>
          {status === 'success' ? t('REGISTRATION_SUCCESS') : t('REGISTRATION_FAILED')}
        </div>
        <div className={'d-flex justify-content-center'}>
          <button
            className={'btn btn-black-hover-light w-50 p-2'}
            onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
            LOGIN
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationActivation;

'use client';
import React, { useEffect } from 'react';
import { setUser, toggleAuthModalVisible } from '@/app/lib/redux';
import { useDispatch } from 'react-redux';
import { AuthActions } from '@/app/ui/components/authModal/index';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { handleAuthApiResponse } from '@/app/utils/profile';
import { trackSSTEvent } from '@/app/utils/tracking';

const Login = ({ apiUrl }: { apiUrl: string }) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);

  // test

  useEffect(() => {
    setErrors([]);
  }, [email, password]);

  const handleLogin = () => {
    if (!email || !password) return;
    fetch(apiUrl + '/user/login', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserEmail: email.trim(),
        Password: password.trim(),
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        handleAuthApiResponse(
          t,
          data,
          () => {
            dispatch(setUser(data.user));
            dispatch(toggleAuthModalVisible(null));
            trackSSTEvent('login', { method: 'email' });
          },
          (e: string[]) => setErrors(e)
        );
      })
      .catch((err) => {
        setErrors([err.message]);
      });
  };
  return (
    <div className={'d-flex flex-column gap-5 gap-lg-2 px-4 py-5 p-lg-5 w-100 justify-content-between'}>
      <div className={'d-flex gap-4'}>
        <button className={'btn btn-white w-50'}>{t('LOGIN')}</button>
        <button
          className={'btn btn-white-hover-border w-50 opacity-50'}
          onClick={() => dispatch(toggleAuthModalVisible(AuthActions.REGISTER))}>
          {t('REGISTER')}
        </button>
      </div>
      <div className={'d-flex flex-column gap-3'}>
        <div className={'text-center fw-bold fs-5'}>{t('LOGIN_WITH_EMAIL')}</div>
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type={'text'}
          placeholder={t('EMAIL')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type={'password'}
          placeholder={t('PASSWORD')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <small
          className={'ms-3 opacity-75 cursor-pointer'}
          onClick={() => dispatch(toggleAuthModalVisible(AuthActions.SEND_RESET_PASSWORD))}>
          {t('FORGOT_PASSWORD')}
        </small>
        <div className={'d-flex justify-content-center'}>
          <button
            className={'btn btn-black-hover-light w-50 p-2'}
            onClick={handleLogin}
            disabled={!email || !password || errors.length > 0}>
            {t('LOGIN')}
          </button>
        </div>

        <div>
          {errors.map((error, index) => (
            <div key={index} className={'text-danger'}>
              {error}
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className={'text-center mb-2'}>{t('OR_CONNECT_WITH')}</div>
        <div className={'d-flex justify-content-center'}>
          <a
            onClick={(e) => {
              e.preventDefault();
              trackSSTEvent('login', { method: 'google' });
              window.location.href = apiUrl + '/user/google-login';
            }}
            href={apiUrl + '/user/google-login'}
            className={'btn btn-white-hover-border d-flex align-items-center gap-2'}>
            <Image src="/intstatic/icons/google.svg" alt="Google" width={18} height={18} />
            {t('GOOGLE_ACCOUNT')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;

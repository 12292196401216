'use client';
import Image from 'next/image';
import { alegreyaSans } from '@/app/utils/fonts';
import styles from './header.module.scss';
import React, { useState } from 'react';
import clsx from 'clsx';
import { DeviceType } from '@mybestbrands/types';
import { useTranslation } from 'next-i18next';
import useClickOutside from '@/app/helpers/hooks/useclickoutside';
import { isLocaleSupported } from '@/app/helpers/locale';

enum CountryEnum {
  DE = 'DE',
  IT = 'IT',
}

interface Countries {
  icon: string;
  label: CountryEnum;
  href: string;
}

const getCountries = (host: string) =>
  Object.values(CountryEnum).map((country) => {
    const subdomain = host.split('.')[0];
    const href =
      country === CountryEnum.DE
        ? `https://${subdomain}.mybestbrands.de/`
        : `https://${subdomain}.mybestbrands.com/${country.toLowerCase()}/`;

    return {
      icon: `/intstatic/icons/languages/${country.toLowerCase()}.png`,
      label: country,
      href,
    };
  });

interface Props {
  device: DeviceType;
  host: string;
}

export function LanguageSwitcher({ device, host }: Props) {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const languageSwitcherRef = useClickOutside(() => setOpen(false));
  const CURRENT_LOCALE = isLocaleSupported(i18n.language)
    ? (i18n.language.toUpperCase() as CountryEnum)
    : CountryEnum.DE;

  const otherCountries = getCountries(host).filter((country) => country.label !== CURRENT_LOCALE);

  function switchLanguage(e: React.MouseEvent<HTMLAnchorElement>, c: Countries) {
    e.preventDefault();
    //setCookie('NEXT_LOCALE', c.label.toLowerCase());
    window.location.href = c.href;
  }

  return (
    <div ref={languageSwitcherRef}>
      <div
        style={{ filter: device !== DeviceType.DESKTOP ? 'brightness(0)' : 'brightness(1)', width: '85px' }}
        className={clsx(`d-flex align-items-center cursor-pointer py-1`, open ? styles.open : styles.closed)}
        onClick={() => setOpen(!open)}>
        <Image className={`mx-2`} src={'/intstatic/icons/globe-icon.png'} alt={'globe'} width={18} height={18}></Image>
        <span className={`text-white ${alegreyaSans.className}`} style={{ fontSize: '0.937rem' }}>
          {CURRENT_LOCALE}
        </span>
        <Image
          className={clsx('mx-2', open ? styles.arrowup : styles.arrowdown)}
          src={'/intstatic/icons/arrow_up.png'}
          alt={'heart'}
          width={16}
          height={8}
        />
      </div>
      <div style={{ filter: device === DeviceType.MOBILE ? 'invert(1)' : 'invert(0)' }}>
        <div
          style={{
            width: '85px',
            backgroundColor: 'black',
            border: '1px solid white',
            padding: '0.7rem',
          }}
          className={clsx('position-absolute py-1 ', !open ? 'd-none' : '')}>
          {otherCountries.map((country) => {
            return (
              <a
                href={country.href}
                onClick={(e) => switchLanguage(e, country)}
                className={`d-flex align-items-center cursor-pointer text-decoration-none`}
                key={country.label}>
                <Image
                  style={{ filter: device === DeviceType.MOBILE ? 'invert(1)' : 'invert(0)' }}
                  src={country.icon}
                  alt={'heart'}
                  width={15}
                  height={10}></Image>
                <span className={`text-white ${alegreyaSans.className} ps-2`} style={{ fontSize: '0.937rem' }}>
                  {country.label}
                </span>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

'use client';
import Image from 'next/image';
import React, { useState } from 'react';
import MobileSearchModal from './MobileSearchModal';
import styles from '../header.module.scss';

import Link from 'next/link';

import { useTranslation } from 'next-i18next';
import { MostSearchedLink } from '@mybestbrands/types';
import useSearchSubmitHandler from '@/app/helpers/hooks/useSearchSubmitHandler';
import { trackSSTEvent } from '@/app/utils/tracking';

interface Props {
  searchedBrands?: MostSearchedLink[] | null;
}

export function MobileSearch({ searchedBrands }: Props) {
  const [isModalOpen, setModalState] = useState(false);
  const searchFormRef = React.createRef<HTMLFormElement>();
  const { t } = useTranslation('common');
  const openModal = () => setModalState(true);
  const closeModal = () => setModalState(false);
  const setFocusOnInput = () => {
    const inputElement = searchFormRef.current?.querySelector('#searchTerm') as HTMLInputElement; // Set focus to the input field
    inputElement?.focus();
  };
  const [searchTerm, setSearchTerm] = useState('');

  const handleSubmit = useSearchSubmitHandler(searchTerm);

  return (
    <>
      <div onClick={openModal}>
        <Image src={'/intstatic/icons/search-white.svg'} alt={'Search Bar'} height={24} width={24} priority />
      </div>

      {isModalOpen && (
        <MobileSearchModal onClose={closeModal}>
          <div className="bg-white w-100 h-100 fixed-top overflow-auto">
            <div className="d-flex aligncenter justify-content-between mt-3 mx-4">
              <span className="" style={{ fontSize: '1.25rem', fontWeight: 700 }}>
                {t('common:SEARCH')}
              </span>
              <Image onClick={closeModal} src="/intstatic/icons/close.svg" alt="Close" height={14} width={14} />
            </div>
            <div className="bg-body-secondary w-100 mt-3 mb-4" style={{ height: '1px' }}></div>
            <div className="mx-4">
              <div className="d-flex align-items-baseline">
                <form
                  ref={searchFormRef}
                  onSubmit={handleSubmit}
                  autoFocus={true}
                  autoComplete="off"
                  className={`${styles['default-theme-no-focus']}`}>
                  <input
                    type="search"
                    id={'searchTerm'}
                    name={'searchTerm'}
                    enterKeyHint="search"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    placeholder={t('common:SEARCH_100_SHOPS')}
                  />
                  <Image
                    onClick={() => {
                      setFocusOnInput();
                      searchFormRef.current?.reset();
                    }}
                    height={17}
                    width={17}
                    src={'/intstatic/icons/clear-search.svg'}
                    alt={'Clear Icon'}
                  />
                </form>
                <div className="ps-4" onClick={() => handleSubmit()}>
                  <Image src="/intstatic/icons/search.svg" alt="Close" height={26} width={26} />
                </div>
              </div>
              {searchedBrands && searchedBrands.length && (
                <div className="mt-5">
                  <span style={{ fontSize: '20px' }}>Ricerche preferite:</span>
                  <ul style={{ listStyle: 'none', padding: 0 }}>
                    {searchedBrands.map((brand) => (
                      <li key={brand.label} className="pt-2 fst-italic" style={{ fontSize: '20px' }}>
                        <Link className="text-decoration-none text-black" href={brand.link}>
                          {brand.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </MobileSearchModal>
      )}
    </>
  );
}

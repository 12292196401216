'use client';
import React, { MouseEvent, useEffect, useState } from 'react';

import smoothscroll from 'smoothscroll-polyfill';
import styles from './styles.module.scss';
import useScrollToTop from '@/app/helpers/hooks/useScrollToTop';
import Image from 'next/image';

export function ToTop() {
  const showBtnFromPx = 500;
  const [active, setActive] = useState(false);
  const scrollTop = useScrollToTop();

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (scrollTop > showBtnFromPx) {
      if (!active) {
        setActive(true);
      }
    } else {
      if (active) setActive(false);
    }
  }, [scrollTop, showBtnFromPx, active]);

  const scrollToTop = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setActive(false);
    }, 1500);
  };

  return (
    <span className={`${styles.initialButton} ${active ? styles.block : styles.nonBlock}`} onClick={scrollToTop}>
      <Image width={48} height={48} src={'/intstatic/icons/arrow-up.png'} alt="go to top" />
    </span>
  );
}

import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { handleAuthApiResponse } from '@/app/utils/profile';
import { trackSSTEvent } from '@/app/utils/tracking';

type Props = {
  apiUrl: string;
  onClose: () => void;
};

const NewsletterGoogle = ({ apiUrl, onClose }: Props) => {
  const { t } = useTranslation('profile');

  const [errors, setErrors] = React.useState<string[]>([]);
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    trackSSTEvent('sign_up', { method: 'google' });
  }, []);

  const handleSubscribe = () => {
    if (!checked) return;
    fetch(apiUrl + '/user/set-services', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Newsletter: true,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        handleAuthApiResponse(
          t,
          data,
          () => onClose(),
          (e: string[]) => setErrors(e)
        );
      });
  };

  return (
    <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
      <div className={'d-flex flex-column gap-4'}>
        <div className={'text-center fw-bold fs-5'}>Danke, dass Sie sich bei MyBestBrands registriert haben</div>
        <div>
          Sie möchten keine exklusiven Angebote, Preisalarme und neuen Trends verpassen? Dann melden Sie sich jetzt für
          den MyBestBrands-Newsletter an.
        </div>

        <label className={'d-flex align-items-center gap-2'}>
          <input type="checkbox" name="gender" value={1} checked={checked} onChange={(e) => setChecked(!checked)} />
          <span>Ich stimme den Datenschutzrichtlinien zu</span>
        </label>

        <small>
          Sie können sich jederzeit vom Newsletter abmelden. Hierzu finden Sie am Ende jedes Newsletters einen
          entsprechenden Link. Weitere Hinweise finden Sie in den Informationen zum Datenschutz.
        </small>

        <div className={'d-flex  justify-content-center'}>
          <div className={'d-flex justify-content-center gap-3 w-75'}>
            <button className={'btn btn-white p-2 w-50'} onClick={handleSubscribe}>
              FÜR NEWSLETTER ANMELDEN
            </button>
            <button className={'btn btn-white-hover-border p-2 w-50 opacity-50 _close'} onClick={onClose}>
              NEIN, DANKE
            </button>
          </div>
        </div>

        {errors.map((error, index) => (
          <div key={index} className={'text-danger'}>
            {error}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsletterGoogle;

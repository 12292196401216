'use client';
import React from 'react';
import styles from './modalsearch.module.scss';

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ children }) => {
  return <div className={styles.modalContainer}>{children}</div>;
};

export default Modal;

'use client';

import { createInstance } from 'i18next';
import initTranslations from '@/app/i18n';
// eslint-disable-next-line no-restricted-imports
import { I18nextProvider } from 'react-i18next';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  locale: string;
  namespaces: string[];
  resources: any;
}

export default function TranslationsProvider({ children, locale, namespaces, resources }: Props) {
  const i18n = createInstance();

  initTranslations(locale, namespaces, i18n, resources);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

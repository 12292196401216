'use client';
import React, { useEffect, useState } from 'react';
import { toggleAuthModalVisible } from '@/app/lib/redux';
import { useDispatch } from 'react-redux';
import { AuthActions } from '@mybestbrands/types';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { handleAuthApiResponse } from '@/app/utils/profile';

type Props = {
  apiUrl: string;
};

const genders = [
  { label: 'FEMALE', value: 1 },
  { label: 'MALE', value: 2 },
  { label: 'NOT_SPECIFIED', value: 0 },
];

const Register = ({ apiUrl }: Props) => {
  const { t } = useTranslation(['profile']);
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gender, setGender] = useState(0);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const isFormValid = firstName && email && password;

  useEffect(() => {
    setErrors([]);
  }, [firstName, email, password, newsletterAccepted, gender]);

  const handleRegister = () => {
    if (!isFormValid) return;

    fetch(apiUrl + '/user/register', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserEmail: email.trim(),
        FirstName: firstName.trim(),
        Gender: gender,
        Password: password.trim(),
        NewsletterAccepted: newsletterAccepted,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        handleAuthApiResponse(
          t,
          data,
          () => {
            setSuccess(true);
          },
          (e: string[]) => setErrors(e)
        );
      });
  };

  if (success) {
    return (
      <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center align-items-center'}>
        <div className={'text-center fw-bold fs-5'}>{t('REGISTRATION_SUCCESS_MESSAGE')}</div>
        <button
          className={'btn btn-black-hover-light w-50'}
          onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
          {t('LOGIN')}
        </button>
      </div>
    );
  }

  return (
    <div className={'d-flex flex-column gap-4 gap-lg-2 px-4 py-5 p-lg-5 w-100 justify-content-between'}>
      <div className={'d-flex gap-4'}>
        <button
          className={'btn btn-white-hover-border w-50 opacity-50'}
          onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
          {t('LOGIN')}
        </button>
        <button className={'btn btn-white w-50'}>{t('REGISTER')}</button>
      </div>
      <div className={'d-flex flex-column gap-3'}>
        <div className={'text-center fw-bold fs-5'}>{t('REGISTER_WITH_EMAIL')}</div>

        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type="text"
          placeholder={t('EMAIL')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type="password"
          placeholder={t('PASSWORD')}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type="text"
          placeholder={t('FIRST_NAME')}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <div className={'d-flex justify-content-between'}>
          {genders.map((g) => (
            <label key={g.label} className={'d-flex align-items-center gap-2'}>
              <input
                type="checkbox"
                name="gender"
                value={g.value}
                checked={gender === g.value}
                onChange={(e) => setGender(parseInt(e.target.value))}
              />
              <span>{t(g.label)}</span>
            </label>
          ))}
        </div>

        <label className={'d-flex align-items-start gap-2'}>
          <input
            type="checkbox"
            className={'mt-1'}
            checked={newsletterAccepted}
            onChange={(e) => setNewsletterAccepted(e.target.checked)}
          />
          <small>{t('NEWSLETTER')}</small>
        </label>

        <small
          dangerouslySetInnerHTML={{
            __html: t('TERMS_AND_CONDITIONS'),
          }}
        />

        <div className={'d-flex justify-content-center'}>
          <button className={'btn btn-black-hover-light w-50 p-2'} onClick={handleRegister} disabled={!isFormValid}>
            {t('REGISTER')}
          </button>
        </div>

        <div>
          {errors.map((error, index) => (
            <div key={index} className={'text-danger'}>
              {error}
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className={'text-center mb-2'}>{t('OR_CONNECT_WITH')}</div>
        <div className={'d-flex justify-content-center'}>
          <a
            href={apiUrl + '/user/google-login'}
            className={'btn lowercase btn-white-hover-border d-flex align-items-center gap-2 fw-bold'}>
            <Image src="/intstatic/icons/google.svg" alt="Google" width={18} height={18} />
            {t('GOOGLE_ACCOUNT')}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React from 'react';
import { useTranslation } from 'next-i18next';

const arrow = (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 0L5.3625 1.1375L9.9125 5.6875H0V7.3125H9.9125L5.3625 11.8625L6.5 13L13 6.5L6.5 0Z"
      fill="white"
    />
  </svg>
);

const RegistrationImage = () => {
  const { t } = useTranslation(['profile']);
  const [first, ...rest] = t('BANNER_TEXT').split('#');
  return (
    <div
      className={'d-none d-md-flex justify-content-end text-white flex-column position-relative'}
      style={{
        backgroundImage: 'url(/images/register.jpg)',
        width: '800px',
        aspectRatio: '607/911',
        backgroundSize: 'cover',
      }}>
      <div
        style={{
          background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
        }}
        className={'p-3 pb-5'}>
        <h2>{first}</h2>
        <div className={'fs-5'}>
          {rest.map((item) => (
            <div key={item}>
              <span className={'me-2'}>{arrow}</span>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RegistrationImage;

import { Dispatch } from 'redux';
import { setUser, toggleAuthModalVisible } from '@/app/lib/redux';
import { AuthActions, MbbUser } from '@mybestbrands/types';
import { trackSSTEvent } from '@/app/utils/tracking';
import { setCookie } from 'cookies-next';
import { COOKIE_NAMES } from '@/app/constants';

export const handleWishList = (
  user: MbbUser | null,
  isInWishlist: boolean,
  productId: string,
  dispatch: Dispatch,
  apiUrl?: string,
  trackingData?: object | null
) => {
  if (!user) {
    dispatch(toggleAuthModalVisible(AuthActions.LOGIN));
    setCookie(COOKIE_NAMES.WISHLIST_PRODUCT, productId, {
      path: '/',
      domain: 'mybestbrands.de',
    });
    return;
  }

  fetch(`${apiUrl}/user/favoriteproducts`, {
    method: isInWishlist ? 'DELETE' : 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: productId }),
  })
    .then((res) => res.json())
    .then(() => {
      const wlData = user?.WatchlistProducts || [];
      const updatedWishlist = isInWishlist ? wlData.filter((r: string) => r !== productId) : [...wlData, productId];

      dispatch(setUser({ ...user, WatchlistProducts: updatedWishlist }));
      {
        !isInWishlist && trackSSTEvent('add_to_wishlist', trackingData || null);
      }
    });
};

'use client';
import styles from './styles.module.scss';
import React from 'react';

const CmpLink = ({ label }: { label: string }) => {
  return (
    <span
      className={styles.navLink}
      onClick={() => {
        if (typeof window.__ucCmp === 'undefined') return;
        window.__ucCmp.showSecondLayer();
      }}>
      {label}
    </span>
  );
};

export default CmpLink;

'use client';
import React, { MouseEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingsState, setUser, toggleAuthModalVisible } from '@/app/lib/redux';
import Login from '@/app/ui/components/authModal/login';
import { useRouter, useSearchParams } from 'next/navigation';
import RegistrationActivation from '@/app/ui/components/authModal/registrationActivation';
import ResetPassword from '@/app/ui/components/authModal/resetPassword';
import Register from '@/app/ui/components/authModal/register';
import Image from 'next/image';
import RegistrationImage from '@/app/ui/components/authModal/image';
import SendResetPassword from '@/app/ui/components/authModal/sendResetPassword';
import NewsletterGoogle from '@/app/ui/components/authModal/newsletterGoogle';
import { MbbUser } from '@mybestbrands/types';
import { deleteCookie, getCookie } from 'cookies-next';
import { handleWishList } from '@/app/helpers/wishlist';
import { COOKIE_NAMES } from '@/app/constants';

export enum AuthActions {
  LOGIN = 'login',
  REGISTER = 'register',
  REGISTRATION_ACTIVATION = 'registration_activation',
  SEND_RESET_PASSWORD = 'send_reset_password',
  RESET_PASSWORD = 'reset_password',
  REGISTER_GOOGLE = 'register_google',
}

const AuthModal = ({ apiUrl }: { apiUrl: string }) => {
  const dispatch = useDispatch();
  const params = useSearchParams();
  const router = useRouter();
  const { authModalVisible } = useSelector(appSettingsState);

  const openAuthModal = (action: string, status: string, email: string, token: string) => {
    const key = `${action}:${status}:${email}:${token}`;

    const unknownAction = !Object.values(AuthActions).includes(action as AuthActions);
    if (unknownAction) return;

    const isEmptyKey = key === ':::';
    dispatch(toggleAuthModalVisible(isEmptyKey ? null : key));
  };

  useEffect(() => {
    fetch(apiUrl + '/user/get', {
      method: 'GET',
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((data: MbbUser) => {
        if (data.User) {
          dispatch(setUser(data));
          const wishlistProductId = getCookie(COOKIE_NAMES.WISHLIST_PRODUCT);
          if (wishlistProductId) {
            handleWishList(data, false, wishlistProductId as string, dispatch, apiUrl);
            deleteCookie(COOKIE_NAMES.WISHLIST_PRODUCT, {
              path: '/',
              domain: 'mybestbrands.de',
            });
          }
        }
      })
      .catch(() => {}); // not logged in
  }, []);

  useEffect(() => {
    const action = params.get('action');
    const status = params.get('status');
    const email = params.get('email');
    const token = params.get('token');
    openAuthModal(action || '', status || '', email || '', token || '');
  }, [params]);

  const closeModal = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('action');
    url.searchParams.delete('status');
    url.searchParams.delete('email');
    url.searchParams.delete('token');

    router.replace(url.toString());
    dispatch(toggleAuthModalVisible(null));
  };

  const handleCLose = (e: MouseEvent<HTMLElement>) => {
    const clickedElement = (e.target as Element).className;
    if (clickedElement.indexOf('_close') > -1) {
      closeModal();
    }
  };

  if (!authModalVisible) return null;

  const [action, status, email, token] = authModalVisible.split(':');

  return (
    <div
      style={{
        inset: 0,
        zIndex: 3,
        backgroundColor: 'rgba(0,0,0,0.2)',
      }}
      className={'position-fixed d-flex justify-content-center align-items-center _close'}>
      <div
        className={'bg-white position-relative m-3'}
        style={{
          maxWidth: '900px',
          width: '100%',
          maxHeight: '95vh',
          overflow: 'auto',
        }}>
        <div
          className={'position-absolute'}
          style={{
            top: 16,
            right: 16,
          }}>
          <Image
            onClick={handleCLose}
            width={22}
            height={22}
            className={'pb-2 ps-2 cursor-pointer _close'}
            src="/intstatic/icons/close.svg"
            alt="close"
          />
        </div>

        <div className={'bg-white d-flex'}>
          {action !== AuthActions.REGISTER_GOOGLE && <RegistrationImage />}
          {action === AuthActions.LOGIN && <Login apiUrl={apiUrl} />}
          {action === AuthActions.REGISTER && <Register apiUrl={apiUrl} />}
          {action === AuthActions.REGISTRATION_ACTIVATION && <RegistrationActivation apiUrl={apiUrl} status={status} />}
          {action === AuthActions.SEND_RESET_PASSWORD && <SendResetPassword apiUrl={apiUrl} />}
          {action === AuthActions.RESET_PASSWORD && <ResetPassword apiUrl={apiUrl} email={email} token={token} />}
          {action === AuthActions.REGISTER_GOOGLE && <NewsletterGoogle apiUrl={apiUrl} onClose={closeModal} />}
        </div>
      </div>
    </div>
  );
};

export default AuthModal;

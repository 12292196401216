import { toggleAuthModalVisible } from '@/app/lib/redux';
import { AuthActions } from '@mybestbrands/types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { handleAuthApiResponse } from '@/app/utils/profile';

type Props = {
  apiUrl: string;
};

const SendResetPassword = ({ apiUrl }: Props) => {
  const { t } = useTranslation(['profile']);
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState<string[]>([]);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    setErrors([]);
  }, [email]);

  const sendReset = () => {
    if (!email) return;

    fetch(apiUrl + '/user/send-reset-password', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        UserEmail: email,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        handleAuthApiResponse(
          t,
          data,
          () => {
            setSuccess(true);
          },
          (e: string[]) => setErrors(e)
        );
      });
  };

  if (success) {
    return (
      <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
        <div className={'d-flex flex-column gap-3'}>
          <div className={'text-center fw-bold fs-5'}>{t('RESET_PASSWORD_SUCCESS_MESSAGE')}</div>
          <div className={'d-flex justify-content-center'}>
            <button
              className={'btn btn-black-hover-light w-50 p-2'}
              onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
              {t('BACK_TO_LOGIN')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={'d-flex flex-column gap-2 p-5 w-100 justify-content-center'}>
      <div className={'d-flex flex-column gap-3'}>
        <div className={'text-center fw-bold fs-5'}>{t('RESET_PASSWORD_TITLE')}</div>
        <input
          className={'bg-body-secondary border-0 px-3 py-1'}
          type={'text'}
          placeholder={t('EMAIL')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className={'d-flex justify-content-center'}>
          <button
            className={'btn btn-black-hover-light w-50 p-2'}
            onClick={sendReset}
            disabled={!email || errors.length > 0}>
            {t('RESET_PASSWORD')}
          </button>
        </div>
        <div className={'d-flex justify-content-center'}>
          <div
            className={'d-flex align-items-center cursor-pointer'}
            onClick={() => dispatch(toggleAuthModalVisible(AuthActions.LOGIN))}>
            <Image width={18} height={14} src="/intstatic/icons/black-arrow-left.svg" alt="close" />
            <div>{t('BACK_TO_LOGIN')}</div>
          </div>
        </div>
        {errors.map((error, index) => (
          <div key={index} className={'text-danger'}>
            {error}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SendResetPassword;

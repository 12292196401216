import { Config } from 'next-i18n-router/dist/types';

export const NAMESPACES = {
  COMMON: 'common',
  FILTERS: 'filters',
  HOME_PAGE: 'homepage',
  NAVIGATION: 'navigation',
  NOT_FOUND_PAGE: 'notfoundpage',
  PATHS: 'paths',
  PROFILE: 'profile',
};

export const i18nConfig: Config = {
  locales: ['it', 'de'],
  defaultLocale: 'de',
  prefixDefault: false,
  localeDetector: false,
};

'use client';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingsState, toggleAuthModalVisible } from '@/app/lib/redux';
import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import { AuthActions } from '@mybestbrands/types';

export function ProfileLinks({ ftEnabled }: { ftEnabled?: boolean }) {
  const dispatch = useDispatch();
  const { user } = useSelector(appSettingsState);

  const openAuthModal = (actionType: AuthActions) => {
    dispatch(toggleAuthModalVisible(actionType));
  };

  const userLinks = () => (
    <div className="d-flex align-items-center gap-3">
      <Link href={'/profile/wishlist'} className={'cursor-pointer'}>
        <Image src={`/intstatic/icons/white-heart.svg`} alt={'Heart Icon'} width={17.5} height={15} />
      </Link>
      <Link href={'/profile/'} className={'cursor-pointer'}>
        <Image src={`/intstatic/icons/sign-in.svg`} alt={'Sign In Icon'} width={15} height={16} />
      </Link>
      <span className="bg-white mx-1" style={{ width: '1px', height: '1rem' }}></span>
    </div>
  );

  const authLinks = () => (
    <div className="d-flex align-items-center gap-3">
      <div onClick={() => openAuthModal(AuthActions.LOGIN)} className={'cursor-pointer'}>
        <Image src={`/intstatic/icons/white-heart.svg`} alt={'Heart Icon'} width={17.5} height={15} />
      </div>
      <div onClick={() => openAuthModal(AuthActions.LOGIN)} className={'cursor-pointer'}>
        <Image src={`/intstatic/icons/sign-in.svg`} alt={'Profile Icon'} width={15} height={17} />
      </div>
      <span className="bg-white mx-1" style={{ width: '1px', height: '1rem' }}></span>
    </div>
  );

  if (user) return <div className="d-flex w-100 align-items-center">{userLinks()}</div>;

  if (!ftEnabled) return null;

  return <div className="d-flex w-100 align-items-center">{user ? userLinks() : authLinks()}</div>;
}

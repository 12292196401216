'use client';
import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';
import { COOKIE_NAMES } from '@/app/constants';
import { setCookie } from 'cookies-next';
import { useSelector } from 'react-redux';
import { appSettingsState } from '@/app/lib/redux';

export function ProductOverlayHandling() {
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams ?? '');
  const { detailModalVisible, authModalVisible } = useSelector(appSettingsState);

  // handle closing PDP overlay on browser back button
  useEffect(() => {
    if (detailModalVisible || authModalVisible) {
      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.height = 'auto';
      document.body.style.overflow = 'initial';
    }
  }, [detailModalVisible, authModalVisible]);

  // If product is loaded from server (link from outside), set cookie, so we know how to route back when closing PDP
  useEffect(() => {
    if (params.has('product') || params.has('pdp_overlay')) {
      setCookie(COOKIE_NAMES.PRODUCT_LOADED_FROM_SERVER, '1');
      setCookie(COOKIE_NAMES.PDP_OVERLAY_ENABLED, '1');
    }
  }, []);
  return null;
}

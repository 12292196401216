import { FilterOptionKeys, FilterOptions, MenuType, SearchParams } from '@mybestbrands/types';

export const priceWithDigits = (price: number) => (price / 100).toFixed(2).replace('.', ',');

export const camelize = (text: string) =>
  text
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
    .join(' ');

export const reduceConsentsArrayToObject = (arr: string[]) =>
  arr.reduce((a: AccessObjectByStringKey, k: string) => Object.assign(a, { [k]: true }), {});

export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

function levenshteinDistance(str1: string, str2: string) {
  const m = str1.length;
  const n = str2.length;
  const dp = new Array(m + 1).fill(null).map(() => new Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) {
    for (let j = 0; j <= n; j++) {
      if (i === 0) dp[i][j] = j;
      else if (j === 0) dp[i][j] = i;
      else if (str1[i - 1] === str2[j - 1]) dp[i][j] = dp[i - 1][j - 1];
      else dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
    }
  }

  return dp[m][n];
}

export function isFuzzyMatch(str1: string, str2: string, maxDistance = 2) {
  const distance = levenshteinDistance(str1.toLowerCase(), str2.toLowerCase());
  return distance <= maxDistance;
}

export const appendGenderPathToCategoriesUrl = (cat: MenuType[], path: string, shouldAppend: boolean) => {
  if (!shouldAppend) return cat;
  const genderPath = path.toLowerCase();
  let isMonoGender;
  return cat.map((category) => {
    isMonoGender = category.gender !== 'neutral';
    return {
      ...category,
      url: category.url + (!isMonoGender ? genderPath + '/' : ''),
      children: category.children?.map((child) => {
        isMonoGender = child.gender !== 'neutral';
        return {
          ...child,
          url: child.url + (!isMonoGender ? genderPath + '/' : ''),
          children: child.children?.map((subChild) => {
            isMonoGender = subChild.gender !== 'neutral';
            return {
              ...subChild,
              url: subChild.url + (!isMonoGender ? genderPath + '/' : ''),
            };
          }),
        };
      }),
    };
  });
};

export const ExtractShippingInfo = (shippingInfo: string | null | undefined): string => {
  if (!shippingInfo) return '';

  const decimalRegex = /\d+\.\d{2}/;
  if (decimalRegex.test(shippingInfo)) {
    return '';
  }

  const parts = shippingInfo.split('|');
  const firstPart = parts[0].trim();

  const regex = /(\d+\s*(?:-|to|bis|–)?\s*\d*)\s*(?:Werktage|Tage|days|Arbeitstagen|Werktagen)?/i;
  const match = firstPart.match(regex);

  if (match) {
    const [fullMatch, days] = match;
    return `${days.trim()} Werktage`;
  } else {
    return ''; // If no match found, clear the shippingInfo
  }
};

export const getSelectedFilterCount = (
  filterOptions: FilterOptions | null,
  key: string,
  searchParams: SearchParams
) => {
  if (!filterOptions || !filterOptions[key as FilterOptionKeys]) return 0;

  if (key === FilterOptionKeys.PRICE) {
    const priceSelected = (searchParams.filters?.price?.length ?? 0) > 0;
    const saleSelected = searchParams.filters?.sale ?? false;
    const couponSelected = searchParams.filters?.coupon ?? false;
    const hottestSaleSelected = searchParams.filters?.hottest_sale ?? false;
    return (priceSelected ? 1 : 0) + (saleSelected ? 1 : 0) + (couponSelected ? 1 : 0) + +(hottestSaleSelected ? 1 : 0);
  }

  return filterOptions[key as FilterOptionKeys].options.filter((option) => option.checked).length;
};

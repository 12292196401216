import { IProduct } from '@mybestbrands/types';

export const getRecentViewsOnProducts = (product: IProduct) => {
  const today = new Date().getDate(); // Expected to be 25 on Oct 25
  const productHashInt = parseInt(product.randomHashDaily, 16); // Convert product_hash to integer
  const criteria = productHashInt % today; // Modulo calculation

  return criteria < today / 2; // Returns true only if criteria is met
};

export const calculateTimeRemaining = (endTime: string): string => {
  const endDate = new Date(endTime);
  const countdownStart = new Date(endDate.getTime() - 48 * 60 * 60 * 1000);

  // Only start countdown 48 hours before end time
  if (new Date() < countdownStart) return '';

  const now = new Date();
  const difference = endDate.getTime() - now.getTime();

  if (difference <= 0) {
    return '';
  } else {
    const totalHours = Math.floor(difference / (1000 * 60 * 60)); // Calculate total hours
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
    return `${totalHours}h ${minutes}m`;
  }
};

export const isWithinCountdownInterval = (endTime?: string): boolean => {
  if (!endTime) {
    return false;
  }

  const endDate = new Date(endTime);
  const now = new Date();

  const difference = endDate.getTime() - now.getTime();
  return !(difference > 48 * 60 * 60 * 1000 || difference <= 0);
};
